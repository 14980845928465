import styles from './index.module.scss';
import Cookie from 'js-cookie';
import { useIntl } from 'react-intl';
interface ChildComponentProps {
    setPopUpR18: any;
}
const R18PopUp: React.FC<ChildComponentProps> = ({ setPopUpR18 }) => {
    const intl = useIntl();

    const confirm = () => {
        setPopUpR18(false)
        Cookie.set('R18', 'true', { expires: 365 });
    };
    return (
        <div className={styles.Pack}>
            <div className={styles.popUps}>
                <h2>
                    <img src="/img/decorate-image.png" alt=""/>
                    <img src="/img/decorate-imageH5.png" alt="" className={styles.decorateh5}/>
                    {intl.formatMessage({ id: "18PopUp1" })}
                    <img src="/img/decorate-image.png" alt="" style={{transform: 'rotateY(180deg)'}}/>
                    <img src="/img/decorate-imageH5.png" alt="" style={{transform: 'rotateY(180deg)'}} className={styles.decorateh5}/>
                </h2>
                <p>{intl.formatMessage({ id: "18PopUp2" })}</p>
                <div className={styles.buttonPack}>
                    <div className={styles.closeButton} onClick={()=>{window.location.href = 'https://www.google.com/'}}>{intl.formatMessage({ id: "18PopUp3" })}</div>
                    <div className={styles.confirmButton} onClick={()=>confirm()}>{intl.formatMessage({ id: "18PopUp4" })}</div>
                </div>
            </div>
        </div>
    )
}

export default R18PopUp;