import { useState } from 'react';
import styles from './index.module.scss';
import { useIntl } from 'react-intl';
interface ChildComponentProps {
    sendDataToParent: any;
}
const SwiperP: React.FC<ChildComponentProps> = ({ sendDataToParent }) => {
    const [inputValue, setInputValue] = useState('');
    const [isValid, setIsValid] = useState(0);
    const [message, setMessage] = useState('');
    const intl = useIntl();

    const handleInputChange = (e:any) => {
        const value = e.target.value;
        setInputValue(value);

        const validCodes = ['YNCJTG01', 'YNCJQQ01', 'YNCJQQ02', 'YNCJQQ03', 'YNCJQQ04', 'YNCJDC01', 'YNCJLN01', 'LNCJPT01', 'YNZZMSB666', 'YNZZMSB888', 'YNZZMSB999'];
        if(value.length>=6){
            if (validCodes.includes(value)) {
                setIsValid(1);
                setMessage(intl.formatMessage({ id: "shadow1" }))
            } else {
                setIsValid(2);
                setMessage(intl.formatMessage({ id: "shadow2" }))
            }
        }else{
            setIsValid(0);
        }
    };
    const handleSubmit = () => {
        if(isValid===1){
            window.location.href = 'https://masobu.forms.app/lottery-application';
        }
    };
    return (
        <div className={styles.Pack} onClick={()=>sendDataToParent(false)}>
            <div className={styles.popUps} onClick={(e)=>e.stopPropagation()}>
                <h2>{intl.formatMessage({ id: "shadow3" })}</h2>
                <ul>
                    {/* <li>
                        <img src="/img/footer3.png" alt="" />
                    </li> */}
                    {/* <li>
                        <a href="https://discord.gg/xWhBxUYsDG" target="_blank">
                            <img src="/img/footer8.png" alt="" />
                        </a>
                    </li> */}
                    <li>
                        <a href="https://t.me/haiwang_game" target="_blank">
                            <img src="/img/footerTG.png" alt="" />
                        </a>
                    </li>
                    {/* <li>
                        <img src="/img/footerQQ.png" alt="" />
                    </li> */}
                    <li>
                        <a href="https://lin.ee/g0Of3BO" target="_blank">
                            <img src="/img/footerLINE.png" alt="" />
                        </a>
                    </li>
                </ul>
                <div className={`${styles.inputPack} ${isValid===2 ? styles.failed : isValid===1&&styles.success}`}>
                    <input placeholder={intl.formatMessage({ id: "shadow4" })} type="text" value={inputValue}  onChange={handleInputChange} />
                    {isValid===1||isValid===2?<p><img src={isValid===1?'/img/success_icon.png':'/img/mistake_icon.png'} alt="" />{message}</p>:''}
                </div>
                <div className={styles.buttonPack}>
                    <div className={styles.closeButton} onClick={()=>sendDataToParent(false)}>{intl.formatMessage({ id: "shadow5" })}</div>
                    <div className={`${styles.confirmButton} ${isValid===1&&styles.success}`} onClick={handleSubmit}>{intl.formatMessage({ id: "shadow6" })}</div>
                </div>
            </div>
        </div>
    )
}

export default SwiperP;