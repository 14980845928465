import { motion, useAnimation } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';
import Image from 'next/image'
import Logo from '~/public/img/logo.png'
import IconTop1 from '~/public/img/iconTop1.png'
import IconTop2 from '~/public/img/iconTop2.png'
import IconTop3 from '~/public/img/iconTop3.png'
import IconTop4 from '~/public/img/iconTop4.png'
import IconTop5 from '~/public/img/iconTop5.png'
import IconTop6 from '~/public/img/seaKing.png'
import IconLine from '~/public/img/icon_line.png'
import IconTg from '~/public/img/icon_tg.png'
import IconDiscord from '~/public/img/icon_discord.png'
import IconQQ from '~/public/img/icon_qq.png';
import IconExpand from '~/public/img/icon-expand.png'
import Header1 from '~/public/img/header1.png'
import SwiperTitleIcon2 from '~/public/img/swiperTitle_icon2.png'
import eventBus from '../../utils/eventBus';



interface ChildComponentProps {
    sendDataToParent: (data: boolean) => void;
    changeLocale: any
}
const Header: React.FC<ChildComponentProps> = ({ sendDataToParent, changeLocale }) => {
    const [isAnimationOpen, setIsAnimationOpen] = useState(false);
    const [isContact, setIsContact] = useState(false);
    const [isLangContact, setIsLangContact] = useState(false);
    const [isHover, setIsHover] = useState(false);
    const headerRRef = useRef(null);
    const intl = useIntl();
    const router = useRouter();
    const [isPrivacyProtocol, setIsPrivacyProtocol] = useState(false);
    const [dc, setDc] = useState('');
    useEffect(() => {
        // 检查当前路径是否包含 /privacyProtocol
        if (router.asPath.includes('/privacyProtocol') || router.asPath.includes('/userProtocol')) {
            setIsPrivacyProtocol(true);
        } else {
            setIsPrivacyProtocol(false);
        }
    }, [router.asPath]);
    const variants = {
        hidden: { x: '100%' },
        visible: { x: 0 },
    };

    useEffect(() => {
        const queryParams = window.location.search;
        setDc(queryParams || '?');
        const handleClickOutside = (event: MouseEvent) => {
            if (headerRRef.current && !(headerRRef.current as HTMLElement).contains(event.target as Node)) {
                setIsAnimationOpen(false);
                setIsHover(false)
            }

        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const currentPath = router.asPath;
    return (
        <>
            <div className={styles.headerBox}>
                <div className={styles.headerL}>
                    <Link href={'/'}>
                        <Image src={Logo} alt="" />
                    </Link>

                </div>
                <div className={styles.headerR}>
                    <Link href={'/rolePage'}>
                        <div className={`${styles.options} ${currentPath.includes('rolePage') ? styles.selected : ''}`}>
                            <Image src={IconTop1} alt="" />
                            {intl.formatMessage({ id: "head.characterIntroduction" })}
                        </div>
                    </Link>
                    <i></i>
                    <Link href={'/announcement'}>
                        <div className={`${styles.options} ${currentPath.includes('c') ? styles.selected : ''}`}>
                            <Image src={IconTop3} alt="" />
                            {intl.formatMessage({ id: "head.announcement" })}
                        </div>
                    </Link>
                    <i></i>
                    <Link href={'/#div3'}>
                        <div className={`${styles.options} ${currentPath.includes('/#div3') ? styles.selected : ''}`}>
                            <Image src={IconTop2} alt="" />
                            {intl.formatMessage({ id: "head.storySynopsis" })}
                        </div>
                    </Link>
                    <i></i>
                    <div className={styles.options}>
                        <Image src={IconTop4} alt="" />
                        {intl.formatMessage({ id: "head.contactSupport" })}
                        <div className={styles.connectListPack}>
                            <ul>
                                {/* <a href="https://discord.gg/Psv6B7tr2R" target="_blank">
                                    <li><Image src={IconDiscord} alt="" />Discord</li>
                                </a> */}
                                <a href="http://lin.ee/g0Of3BO" target="_blank">
                                    <li><Image src={IconLine} alt="" />Line</li>
                                </a>
                                <a href="https://t.me/haiwang_game" target="_blank">
                                    <li><Image src={IconTg} alt="" />Telegram</li>
                                </a>
                                <li>
                                    <Image src={IconQQ} alt="" />QQ
                                    <div className={styles.hovPack}>
                                        {/* <a href="https://qm.qq.com/q/Mwfu6iBn2K" target="_blank">
                                            <div>海王传奇1</div>
                                        </a> */}
                                        {/* <a href="https://qm.qq.com/q/ZGTuBYgRKG" target="_blank">
                                            <div>海王传奇2</div>
                                        </a> */}
                                        <a href="https://qm.qq.com/q/S3lpRywW8C" target="_blank">
                                            <div>海王传奇3</div>
                                        </a>
                                        <a href="https://qm.qq.com/q/yQ6IsOtVxC" target="_blank">
                                            <div>海王传奇4</div>
                                        </a>
                                        <a href="https://qm.qq.com/q/HSuy2Dng6i" target="_blank">
                                            <div>海王传奇5</div>
                                        </a>
                                        <a href="https://qm.qq.com/q/YDlQI3n5ks" target="_blank">
                                            <div>海王传奇6</div>
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <i></i>
                    <div className={styles.options}>
                        <Image src={IconTop5} alt="" />
                        {intl.formatMessage({ id: "head.joinLanguage" })}
                        <div className={styles.connectListPack}>
                            <ul>
                                {/* <li onClick={() => changeLocale('en')}>English</li> */}
                                <li onClick={() => changeLocale('zh-CN')}>简体中文</li>
                                <li onClick={() => changeLocale('zh-TW')}>繁體中文</li>
                            </ul>
                        </div>
                    </div>
                    {/* <div className={styles.buyButton}  onClick={()=>sendDataToParent(true)}> {intl.formatMessage({ id: "head.drawFreePlayQualification" })}</div> */}
                </div>
                {/* onClick={() => sendDataToParent(true)} */}
                {/* https://vsde.lpio5tymk.com/game/detail${dc}&id=26 */}
                {/* <a href={`https://soa.wkdmnzksr.com/game/detail?dc=mgwt0005&id=26&utm_source=eventpage&utm_medium=campaign&utm_campaign=collect`}> */}
                <div className={styles.buyButton}>
                    <Image src={SwiperTitleIcon2} alt="" className={styles.iconR} />
                    {intl.formatMessage({ id: "head.drawFreePlayQualification2" })}
                    <Image src={SwiperTitleIcon2} alt="" />
                </div>
                {/* </a> */}
            </div>
            <div className={styles.booth}></div>
            <div className={styles.headerH5}>
                <div className={styles.headerL}>
                    <Link href={'/'}>
                        <Image src={Logo} alt="" />
                    </Link>

                </div>
                <div className={styles.headerR}>
                    {/* onClick={() => sendDataToParent(true)} */}
                    {/* <a href={`https://soa.wkdmnzksr.com/game/detail?dc=mgwt0005&id=26&utm_source=eventpage&utm_medium=campaign&utm_campaign=collect`}> */}
                    <div className={styles.buyButton}>
                        {intl.formatMessage({ id: "head.drawFreePlayQualification2" })}
                    </div>
                    {/* </a> */}

                    <div ref={headerRRef} onClick={() => setIsAnimationOpen(!isAnimationOpen)} className={styles.headerSwitch}>
                        <Image src={Header1} alt="" />
                    </div>
                </div>
                {isAnimationOpen && <div className={styles.bgPack}></div>}

                <motion.div
                    initial="hidden"
                    animate={isAnimationOpen ? 'visible' : 'hidden'}
                    variants={variants}
                    transition={{ duration: 0.3 }}
                    className={styles.menuPack}>
                    <Link href="/rolePage">
                        <div className={styles.options}>
                            <Image src={IconTop1} alt="" className={styles.optionsImg} />
                            {intl.formatMessage({ id: "head.characterIntroduction" })}
                        </div>
                    </Link>

                    <Link href="/announcement">
                        <div className={styles.options}>
                            <Image src={IconTop3} alt="" className={styles.optionsImg} />
                            {intl.formatMessage({ id: "head.announcement" })}
                        </div>
                    </Link>
                    <Link href="/#div3">
                        <div className={styles.options}>
                            <Image src={IconTop2} alt="" className={styles.optionsImg} />
                            {intl.formatMessage({ id: "head.storySynopsis" })}
                        </div>
                    </Link>
                    <div ref={headerRRef}>
                        <div className={styles.options} onClick={() => {
                            setIsContact(!isContact)
                            setIsLangContact(false)
                        }}>
                            <Image src={IconTop1} alt="" className={styles.optionsImg} />
                            {intl.formatMessage({ id: "head.contactSupport" })}
                            <Image src={IconExpand} alt="" className={styles.optionsImg} />
                        </div>
                        {
                            isContact ? <div className={styles.connectListPack}>
                                <ul>
                                    {/* <Link href="https://discord.gg/xWhBxUYsDG" target="_blank">
                                        <li><Image src={IconDiscord} alt="" />Discord</li>
                                    </Link> */}
                                    <Link href="https://lin.ee/g0Of3BO" target="_blank">
                                        <li><Image src={IconLine} alt="" />Line</li>
                                    </Link>
                                    <Link href="https://t.me/haiwang_game" target="_blank">
                                        <li><Image src={IconTg} alt="" />Telegram</li>
                                    </Link>
                                    <li onClick={() => { setIsHover(true) }}>
                                        <Image src={IconQQ} alt="" />QQ
                                        {isHover && <div className={styles.hovPack}>
                                            {/* <a href="https://qm.qq.com/q/Mwfu6iBn2K" target="_blank">
                                                <div>海王传奇1</div>
                                            </a> */}
                                            {/* <a href="https://qm.qq.com/q/ZGTuBYgRKG" target="_blank">
                                                <div>海王传奇2</div>
                                            </a> */}
                                            <a href="https://qm.qq.com/q/S3lpRywW8C" target="_blank">
                                                <div>海王传奇3</div>
                                            </a>
                                            <a href="https://qm.qq.com/q/yQ6IsOtVxC" target="_blank">
                                                <div>海王传奇4</div>
                                            </a>
                                            <a href="https://qm.qq.com/q/HSuy2Dng6i" target="_blank">
                                                <div>海王传奇5</div>
                                            </a>
                                            <a href="https://qm.qq.com/q/YDlQI3n5ks" target="_blank">
                                                <div>海王传奇6</div>
                                            </a>
                                        </div>}
                                    </li>
                                </ul>
                            </div> : ''
                        }
                        <div className={styles.options} onClick={(event) => {
                            event.stopPropagation();
                            setIsLangContact(!isLangContact)
                            setIsContact(false)
                        }}>
                            <Image src={IconTop5} alt="" className={styles.optionsImg} />
                            {intl.formatMessage({ id: "head.joinLanguage" })}
                            <Image src={IconExpand} alt="" className={styles.optionsImg} />

                        </div>
                        {
                            isLangContact ? <div className={styles.connectListPack}>
                                <ul>
                                    {/* <li onClick={() => changeLocale('en')}>English</li> */}
                                    <li onClick={() => changeLocale('zh-CN')}>简体中文</li>
                                    <li onClick={() => changeLocale('zh-TW')}>繁體中文</li>
                                </ul>
                            </div> : ''
                        }
                    </div>
                    <div className={styles.options} onClick={() => eventBus.emit('heartTest', true)}>
                        <Image src={IconTop6} alt="" className={styles.optionsImg} />
                        <span style={{ color: '#C92AFF' }}>{intl.formatMessage({ id: "seaTest" })}</span>
                    </div>
                    {/* <div className={styles.buyButton}  onClick={()=>sendDataToParent(true)} >{intl.formatMessage({ id: "head.drawFreePlayQualification" })}</div> */}
                </motion.div>
            </div>
        </>
    )
}

export default Header;